<template>
    <div class="search-presentation-page">
        <BaseHeader/>
        <main>
            <div class="container">
                <div class="search-wrapper">
                    <SearchInput :value="search" @search="callSearch"/>
                </div>

                <SearchPresentationLoading v-if="loading"/>

                <div v-else-if="presentations.length > 0" class="presentations row">
                    <div v-for="presentation in presentations" :key="presentation.id" class="col-12 col-md-6 col-lg-4">
                        <PresentationPreview :presentation="presentation"/>
                    </div>

                    <div
                        v-if="presentations.length > 0 && presentations.length < count"
                        class="col-12 d-flex justify-content-center mb-4"
                    >
                        <GradientButton :hover="false" @click="loadMore">
                            {{ loadingMore ? $t('loadingMore') : $t('loadMore') }}
                        </GradientButton>
                    </div>
                </div>

                <h1 v-else>Презентации не найдены... Попробуем найти что то другое? :)</h1>
            </div>
        </main>
        <BaseFooter/>
    </div>
</template>

<script>
import BaseHeader from '@/components/Layouts/BaseHeader';
import BaseFooter from '@/components/Layouts/BaseFooter';
import PresentationPreview from '@/components/SearchPresentations/PresentationPreview';
import { mapActions, mapMutations, mapState } from 'vuex';
import SearchInput from '@/components/SearchPresentations/SearchInput.vue';
import SearchPresentationLoading from '@/components/SearchPresentations/SearchPresentationLoading.vue';
import GradientButton from '@/components/styled/GradientButton.vue';

export default {
    name: 'PresentationSearch',
    components: {
        BaseHeader,
        BaseFooter,
        SearchInput,
        GradientButton,
        PresentationPreview,
        SearchPresentationLoading
    },
    data: () => ({
        loading: true,
        loadingMore: false
    }),
    computed: {
        ...mapState({
            count: state => state.viewer.count,
            search: state => state.viewer.search,
            presentations: state => state.viewer.presentations
        })
    },
    methods: {
        ...mapActions('viewer', [
            'searchPresentations'
        ]),
        ...mapMutations('viewer', ['setPage', 'setSearch']),
        async callSearch (search) {
            this.loading = true;
            this.setPage(1);
            this.setSearch(search);

            this.$router.replace({
                path: this.$route.path,
                query: { search }
            });

            await this.searchPresentations();
            this.loading = false;
        },
        async loadMore () {
            if (this.loadingMore) {
                return;
            }

            this.loadingMore = true;
            await this.searchPresentations();
            this.loadingMore = false;
        }
    },
    async created () {
        if (this.$route.query.search) {
            this.setSearch(this.$route.query.search);
        }

        this.setPage(1);
        await this.searchPresentations();
        this.loading = false;
    }
};
</script>

<style scoped lang="scss">
.search-presentation-page {
    padding-top: 80px;
    background: #F5F6F9;

    main {
        min-height: calc(100vh - 30.5px - 100px);

        h1 {
            opacity: 0.86;
            color: #2d2d2d;
            font-weight: 500;
            user-select: none;
            text-align: center;
            position: relative;
            font-family: "Montserrat", sans-serif;
        }

        .search-wrapper {
            margin-bottom: 20px;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "loadMore":  "Load more",
        "loadingMore": "Loading..."
    },
    "ru": {
        "loadMore":  "Загрузить ещё",
        "loadingMore": "Загрузка..."
    }
}
</i18n>
