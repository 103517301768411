<template>
    <button @click="$emit('click')" @mouseleave="mouseLeave" @mouseenter="mouseEnter">
        <span class="effect"></span>
        <div class="btn-content">
            {{ text }}
        </div>
    </button>
</template>

<script>
export default {
    name: 'PreviewButton',
    props: {
        text: {
            type: String
        }
    },
    methods: {
        mouseEnter () {
            this.$el.classList.add('mouse-enter');
            this.$el.classList.remove('mouse-leave');
        },
        mouseLeave () {
            this.$el.classList.remove('mouse-enter');
            this.$el.classList.add('mouse-leave');

            setTimeout(() => this.$el.classList.remove('mouse-leave'), 500);
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/rfs.scss';

button {
    outline: 0;
    padding: 0;
    height: 40px;
    width: 150px;
    border: none;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    border-radius: 0.35rem;

    .btn-content {
        z-index: 2;
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        border-radius: 0.35rem;
        background-color: #fff;
        justify-content: center;
        border: solid 1px #2d2d2d;

        color: #2d2d2d;
        font-weight: 500;
        margin-bottom: 2.5px;
        @include font-size(18px);
        font-family: "Montserrat", sans-serif;
    }

    .effect {
        z-index: 1;
        top: -4.5px;
        left: -4.5px;
        transform: scaleX(0.93) scaleY(0.75);
        width: calc(100% + 4.5px * 2);
        height: calc(100% + 3.5px * 2);
        position: absolute;
        border-radius: 0.35rem;
        border: solid 1px #2d2d2d;
        //transition: all 0.3s ease-in-out;
        box-shadow: 0px 0px 0px 1px #2d2d2d;
        background: linear-gradient(to right, #82568E 0%, #E16B72 100%);
    }

    &.mouse-enter {
        .effect {
            animation: scaleAsync 0.3s linear forwards;
        }
    }

    &.mouse-leave {
        .effect {
            animation: scaleAsyncRevers 0.2s linear forwards;
        }
    }

    &:active {
        .btn-content {
            transform: scale(0.98);
        }
    }

    @keyframes scaleAsync {
        0% {
            transform: scaleX(0.93) scaleY(0.75);
        }
        100% {
            transform: scaleX(1) scaleY(1);
        }
    }

    @keyframes scaleAsyncRevers {
        0% {
            transform: scaleX(1) scaleY(1);
        }
        100% {
            transform: scaleX(0.93) scaleY(0.75);
        }
    }
}
</style>
