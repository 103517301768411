<template>
    <div class="slide-preview-wrapper thumbs-bar__item">
        <SlidePreviewContent :vjson="slide" :size="meta.size" :meta="meta" />
    </div>
</template>

<script>
import SlidePreviewContent from '@/components/SlidePreviewContent.vue';

export default {
    name: 'SlidePreviewWrapper',
    components: { SlidePreviewContent },
    props: {
        slide: Object,
        meta: Object
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/preview.scss';
.slide-preview-wrapper.thumbs-bar__item {
        height: 100%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-top: 0;
        border: none;
        pointer-events: none;
    }
</style>
