<template>
    <div class="search-loading">
        <h1 class="text-center">
            {{ $t('searchLoading') }}
            <span>{{ dots }}</span>
        </h1>
    </div>
</template>

<script>
export default {
    name: 'SearchPresentationLoading',
    data: () => ({
        dots: ''
    }),
    methods: {
        recountDots () {
            const dotLength = this.dots.length;

            if (dotLength === 3) {
                this.dots = '';
            } else {
                this.dots += '.';
            }
        }
    },
    mounted () {
        setInterval(() => this.recountDots(), 300);
    }
};
</script>

<style scoped lang="scss">
.search-loading {
    margin-top: 50px;

    h1 {
        opacity: 0.86;
        color: #2d2d2d;
        font-weight: 500;
        user-select: none;
        text-align: center;
        position: relative;
        font-family: "Montserrat", sans-serif;

        span {
            position: absolute;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "searchLoading": "Looking for presentations"
    },
    "ru": {
        "searchLoading": "Ищем нужные презентации"
    }
}
</i18n>
