<template>
    <div class="presentation-preview-card">
        <div class="preview-wrapper">
            <Loader v-if="preview === null || loadingPreview"/>
            <SlidePreviewWrapper v-else :slide="preview.slides[0]" :meta="preview.meta"/>
        </div>
        <div class="info">
            <div class="info-description">
                <span class="title">{{ presentation.name }}</span>
                <span class="created-at"><b>Создана:</b> {{ presentation.created_at | toDay }}</span>
            </div>
            <div class="info-controls">
                <PreviewButton @click="showPresentation" :text="$t('preview')"/>
            </div>
        </div>
    </div>
</template>

<script>
import { apiGet, customApiRequest } from '@/models/Api';
import JSZip from 'jszip';
import Loader from '@/components/Loader.vue';
import PreviewButton from '@/components/SearchPresentations/PreviewButton.vue';
import dayjs from 'dayjs';
import SlidePreviewWrapper from '@/components/SearchPresentations/SlidePreviewWrapper.vue';
import { mapMutations } from 'vuex';

export default {
    name: 'PresentationPreview',
    provide () {
        return {
            currentPage: this
        };
    },
    components: {
        Loader,
        PreviewButton,
        SlidePreviewWrapper
    },
    props: {
        presentation: Object
    },
    data: () => ({
        preview: null,
        allSlidesURL: null,
        firstSlideURL: null,
        loadingPreview: true
    }),
    methods: {
        ...mapMutations('viewer', ['setPreviewData']),
        async loadPreview () {
            const res = await apiGet(`/api/v1/presentation/${this.presentation?.id}/preview`);

            if (res) {
                this.allSlidesURL = res.all ? `${this.$root.storageUrl}/${res.all}` : null;
                this.firstSlideURL = res.first ? `${this.$root.storageUrl}/${res.first}` : null;

                if (this.firstSlideURL != null) {
                    const preview = await customApiRequest('GET', this.firstSlideURL);

                    if (preview) {
                        try {
                            const zip = await JSZip.loadAsync(preview, { base64: true });
                            const json = await zip.file(Object.keys(zip.files)[0]).async('string');
                            this.preview = await JSON.parse(json);

                            this.$nextTick(() => {
                                this.loadingPreview = false;
                            });
                        } catch (err) {
                            console.log('Error', err);
                        }
                    }
                }
            }
        },
        showPresentation () {
            const data = {
                firstSlide: this.preview,
                allSlidesURL: this.allSlidesURL,
                firstSlideURL: this.firstSlideURL,
                presentation: {
                    ...this.presentation,
                    textContent: this.presentation.text_content
                }
            };

            this.setPreviewData(data);
            this.$router.push(`/presentation-preview/${this.presentation.id}`);
        }
    },
    filters: {
        toDay (value) {
            return dayjs(value).format('DD.MM.YY');
        }
    },
    created () {
        this.loadPreview();
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/rfs.scss';

$presentation-height: 320px;
$preview-height: 240px;
$info-height: calc($presentation-height - $preview-height);

.presentation-preview-card {
    height: $presentation-height;
    overflow: hidden;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 30px;

    .preview-wrapper {
        width: 100%;
        height: $preview-height;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e5e3ea;
    }

    .info {
        display: flex;
        padding: 0 15px;
        flex-direction: row;
        align-items: center;
        height: $info-height;
        justify-content: space-between;

        .info-description {
            display: flex;
            padding-right: 2.5px;
            flex-direction: column;

            .title {
                color: #2d2d2d;
                font-weight: 500;
                margin-bottom: 5px;
                @include font-size(16px);
                font-family: "Montserrat", sans-serif;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .created-at {
                opacity: 0.5;
                color: #3A3B41;
                font-weight: 400;
                @include font-size(12px);
                font-family: "Montserrat", sans-serif;
            }
        }

        .info-controls {

        }
    }
}
</style>

<i18n>
{
    "en": {
        "preview": "Preview"
    },
    "ru": {
        "preview": "Превью"
    }
}
</i18n>
