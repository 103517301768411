<template>
    <span class="loader" :style="{ width, height }"></span>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        width: {
            type: String,
            default: '60px'
        },
        height: {
            type: String,
            default: '90px'
        }
    }
};
</script>

<style scoped>
.loader {
    color: #fff;
    width: 60px;
    height: 90px;
    position: relative;
    transform: rotateX(65deg) rotate(45deg);
    animation: layers1 1s linear infinite alternate;
    /*remove bellows command for perspective change*/
    transform: perspective(200px) rotateX(65deg) rotate(45deg);
    /*transform: perspective(200px) rotateX(0deg) rotate(90deg);*/
    background: linear-gradient(to right, #82568E 0%, #E16B72 100%);
}

.loader:after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.7);
    animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
    0% {
        box-shadow: 0px 0px 0 0px
    }
    90%, 100% {
        box-shadow: 20px 20px 0 -4px
    }
}

@keyframes layerTr {
    0% {
        transform: translate(0, 0) scale(1)
    }
    100% {
        transform: translate(-25px, -25px) scale(1)
    }
}
</style>
